import React from "react";
import "./Footer.css";
export default () => {
  return (
    <div className="container-fluid" id="footer-wrapper">
      <div className="row justify-content-center">
        <a href="/" className="m-2" id="footer-link">
          darraghobrien.com
        </a>
      </div>
    </div>
  );
};
