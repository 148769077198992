import React from "react";
import { GoMarkGithub } from "react-icons/go";
import "./ProjectCard.css";

export default ({ project }) => {
  let githubLink = null;
  if (project.github) {
    githubLink = (
      <a
        className="github-link"
        href={project.github}
        target="_blank"
        rel="noopener noreferrer"
      >
        <GoMarkGithub className="mr-1" />
        View on github
      </a>
    );
  }
  return (
    <div className="row justify-content-center project-card mb-5">
      <div className="col-xs-12 col-sm-8">
        <a href={project.url} target="_blank" rel="noopener noreferrer">
          <img
            alt={project.name + " screenshot"}
            className="img-fluid border p-2 mb-4"
            src={project.img}
          />
        </a>
        <a href={project.url} target="_blank" rel="noopener noreferrer">
          <h3>{project.name}</h3>
        </a>
        <p>{project.blurb}</p>
        {githubLink}
      </div>
    </div>
  );
};
