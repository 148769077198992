import React from "react";
import ProjectCard from "../ProjectCard/ProjectCard";

const projects = [
  {
    id: 1,
    name: "tomobrien.ie",
    blurb:
      "I developed this website in memory of my late Grandfather. He was a poet, storyteller and loving husband. This site is a showcase of his published works, as well as some pictures and videos of his family and friends. The site was developed using bootstrap and jquery on the frontend, with laravel and mysql on the backend.",
    img: "./images/tomobrien.png",
    url: "https://tomobrien.ie",
    github: "https://github.com/darragh3277/tomobrien",
  },
  {
    id: 2,
    name: "pathfinder",
    blurb:
      "Dr. Mike Pound from Computerphile and Clément Mihailescu inspired me to work on this project. I wanted an exercise to dust off some of my old algorithim knowledge. This project allowed me to work on pathfinding and maze generating algorithims. The application is developed using reactJS and Material UI.",
    img: "./images/pathfinder.png",
    url: "https://darraghobrien.com/projects/pathfinder",
    github: "https://github.com/darragh3277/pathfinder",
  },
  {
    id: 3,
    name: "sudoko",
    blurb:
      "I've always been a fan of Sudoko and have often wondered how complex the solutions to a game must be. Over the weekend I decided to make a game for fun with the option to auto-solve. The game is build using reactJS and basic html and css.",
    img: "./images/sudoko.png",
    url: "https://darraghobrien.com/projects/sudoko",
    github: "https://github.com/darragh3277/sudoko",
  },
  {
    id: 4,
    name: "sorter",
    blurb:
      "Algorithims are a fundamental of computer science, while studying for my masters in TUD I wanted to refresh my memory of sorting algorithims. I built sorter as an exercise to sharpen my algorithm skills as well as to give a visual display of sorting algorithms. To develop the site I used react and bootstrap.",
    img: "./images/sorter.png",
    url: "https://darraghobrien.com/projects/sorter",
    github: "https://github.com/darragh3277/sorter",
  },
  {
    id: 5,
    name: "FotoFix",
    blurb:
      "A friend introduced me to fabricjs, a javascript library that provides additional functionality to canvas elements. I wanted to try the library out in a project which resulted in FotoFix.  FotoFix is a very basic web app for applying filters to images. I designed the project to allow for easy configuration of the available filters for an implementation. The site is developed using bootstrap, reactjs and fabricjs.",
    img: "./images/fotofix.png",
    url: "https://darraghobrien.com/projects/fotofix",
    github: "https://github.com/darragh3277/foto_fix",
  },
];

export default () => {
  return (
    <div className="container-fluid">
      <h2 className="display-4 text-center my-5">- projects -</h2>
      {projects.map((project) => {
        return <ProjectCard key={project.id} project={project} />;
      })}
    </div>
  );
};
