import React from "react";
import { GoMarkGithub } from "react-icons/go";
import { AiOutlineMail, AiFillLinkedin } from "react-icons/ai";
import "./Header.css";
export default () => {
  return (
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <h1 className="display-4">Hi, I'm Darragh</h1>
        <p className="lead">
          I'm a software engineer based in Dublin, Ireland and below are some of
          my projects.
        </p>
        <div className="row">
          <div className="col">
            <a
              href="mailto:dobrien.com@gmail.com"
              title="Send an email"
              className="icon-link mr-2"
            >
              <AiOutlineMail />
            </a>
            <a
              href="https://www.linkedin.com/in/darragh3277/"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link mr-2"
              title="LinkedIn"
            >
              <AiFillLinkedin />
            </a>
            <a
              href="https://github.com/darragh3277"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-link mr-2"
              title="View on github"
            >
              <GoMarkGithub />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
